<template>
    <WitModal
        v-model="showModal"
        @input="emitInput"
        size="md"
        title="Before you continue"
        variant="warning"
        customClass="data-provider-preview__required-modal"
        disableDBlock
    >
        <strong>You must first configure the required connector{{ connectors.length > 1 ? 's' : '' }}</strong>
        <br />
        Click on an icon to create the resource. You will continue with creating your report after you're finished.

        <div class="data-provider-preview__type-container">
            <project-router-link
                v-for="(connector, index) in connectors"
                :key="index"
                class="data-provider-preview__type pointer-action"
                :to="`${connector.link}?reportId=${$route.params.id}`"
            >
                <div class="overflow-hidden data-provider-preview__connector-card">
                    <div class="data-provider-preview__connector-card__img">
                        <img :src="connector.image" />
                    </div>
                    <div class="data-provider-preview__connector-card__text">
                        <div class="data-provider-preview__connector-card__name">
                            <h4 class="card-title">{{ connector.displayType }}</h4>
                        </div>
                    </div>
                </div>
            </project-router-link>
        </div>
    </WitModal>
</template>

<script>
import WitModal from '@/components/Modals/WitModal.vue'

export default {
    components: {
        WitModal,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        connectors: {
            type: Array,
            default: () => [],
        },
    },
    watch: {
        value() {
            this.showModal = this.value
        },
    },
    created() {
        this.showModal = this.value
    },
    data() {
        return {
            showModal: false,
        }
    },
    methods: {
        close() {
            this.showModal = false
            this.emitInput()
        },
        emitInput() {
            this.$emit('input', this.showModal)
        },
    },
}
</script>

<style lang="scss">
.data-provider-preview__required-modal {
    .data-provider-preview__type-container {
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        .data-provider-preview__type {
            display: block;
            padding: 0;
            margin: 5px;
            color: black;
            text-decoration: none;

            .data-provider-preview__connector-card {
                padding: 0.25rem 0.5rem;
                background-color: transparent;
                width: 100px;
                height: 100px;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
                justify-content: space-evenly;
                border-radius: 10px;
                background: rgba(255, 195, 43, 0.2);

                &__name {
                    text-align: center;

                    h4 {
                        margin-bottom: 5px;
                        font-size: 0.8rem;
                    }
                }

                &__img {
                    margin-bottom: 5px;

                    img {
                        width: 45px;
                        height: 45px;
                        object-fit: contain;
                    }
                }

                &:hover {
                    background: rgba(255, 195, 43, 0.5);
                }
            }
        }
    }
}
</style>
